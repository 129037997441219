import styled from "styled-components";

const H4 = styled.h4`
  color: white;
  margin: 0px;
  margin-top: 30px;
  margin-bottom: 10px;
`;

export default H4;
